var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm work-permit-map-card",
          attrs: { title: "작업위치(지도)" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addArea },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "지도", icon: "place" },
                        on: { btnClicked: _vm.selectMap },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 container",
              },
              [
                _c(
                  "q-img",
                  {
                    style: {
                      height: String(287.5) + "px",
                      width: String(_vm.ratio * 287.5) + "px",
                    },
                    attrs: {
                      src: _vm.mapSrc,
                      "spinner-color": "white",
                      contain: true,
                    },
                  },
                  [
                    _vm.workPermit.maps && _vm.workPermit.maps.length > 0
                      ? _vm._l(_vm.workPermit.maps, function (item, idx) {
                          return _c(
                            "VueDraggableResizable",
                            {
                              key: idx,
                              ref: "markImage",
                              refInFor: true,
                              staticClass: "markImage",
                              attrs: {
                                resizable: false,
                                parent: true,
                                draggable: _vm.editable && _vm.isWriting,
                                x: item.mobileLocationX,
                                y: item.mobileLocationY,
                                w: 20,
                                h: 20,
                                grid: [10, 10],
                              },
                              on: {
                                dragging: (x, y) => _vm.onDrag(x, y, item),
                              },
                            },
                            [_vm._v(" " + _vm._s(idx + 1) + " ")]
                          )
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 container",
              },
              [
                _vm.workPermit.maps && _vm.workPermit.maps.length > 0
                  ? _vm._l(_vm.workPermit.maps, function (item, idx) {
                      return _c("div", { key: idx, staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c("c-multi-select", {
                              ref: "mark-work-multi-select-" + item.id,
                              refInFor: true,
                              staticClass: "q-pt-md",
                              attrs: {
                                disabled: !_vm.isWriting,
                                editable: _vm.editable,
                                comboItems: _vm.supplyItems,
                                isObject: true,
                                valueText: "sopWorkTypeName",
                                valueKey: "sopWorkTypeCd",
                                itemText: "codeName",
                                itemValue: "code",
                                label: "보충작업 - " + (idx + 1),
                                name: "resultTypes",
                              },
                              on: { datachange: _vm.datachange },
                              model: {
                                value: item.resultTypes,
                                callback: function ($$v) {
                                  _vm.$set(item, "resultTypes", $$v)
                                },
                                expression: "item.resultTypes",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-2",
                            staticStyle: { position: "relative", top: "20px" },
                          },
                          [
                            _vm.editable && _vm.isWriting
                              ? _c("c-btn", {
                                  attrs: { label: "제외", icon: "remove" },
                                  on: {
                                    btnClicked: function ($event) {
                                      return _vm.removePoint(idx)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }